import React, { useCallback, useEffect, useState } from "react";
import ReactSelect, { OptionsOrGroups } from "react-select";
import { useToggle } from "react-use";

import { inputActions } from "@/components/Autocomplete2/constants";
import Select from "@/components/Select";
import { getCleanedAddress } from "../../src/services/suggestions";

const truthy = () => true;

export type OptionType = {
  label: string;
  value: string;
};

export type ExtendedOptionType = OptionType & {
  corrAccount: string;
  bic: string;
  bill: string;
  name: string;
};

type Props = Omit<Parameters<ReactSelect>[0], "inputValue" | "onInputChange"> & {
  error?: string;
  fetcher: (query: string) => Promise<any[]>;
  isDetailedOptions?: boolean;
  required?: boolean;
  readOnly?: boolean;
  optionComponent?: (props: any) => JSX.Element;
  shallow?: boolean;
  smallFontSize?: boolean;
};

/**
 * Компонент WarehouseAutocomplete представляет собой поле ввода с подсказками для складов.
 * Является оберткой над компонентом Autocomplete с функцией поиска складов и отработкой ошибок.
 */

const WarehouseAutocomplete = React.forwardRef(
  (
    {
      value,
      onChange,
      onFocus,
      fetcher,
      isDisabled,
      isDetailedOptions,
      required,
      readOnly,
      optionComponent,
      smallFontSize,
      error,
      ...props
    }: Props,
    ref,
  ) => {
    const [loading, toggleLoading] = useToggle(false);
    const [showValue, toggleShowValue] = useToggle(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<OptionsOrGroups<any, any>[]>([]);
    const [toggle, setToggle] = useState(false);

    const handleInputChange = useCallback(
      (input: any, { action }) => {
        if (action === inputActions.INPUT_BLUR) {
          !value && setInputValue("");
          toggleShowValue(true);

          setToggle(!toggle);
        }
        if (action === inputActions.INPUT_CHANGE || action === inputActions.SET_VALUE) {
          setInputValue(input);
        }
      },
      [value, toggle],
    );

    const handleChange = useCallback(
      async (option: any, meta) => {
        onChange && onChange(option, meta);
        setInputValue(option ? option.value : "");
        setErrorMessage(null);
        if (!option.isSearchable) {
          try {
            toggleLoading(true);
            const response = await getCleanedAddress(option.value);
            console.log(response);
            onChange && onChange(response, meta);
            setInputValue(response?.value ? response.value : "");
          } catch (error: any) {
            console.log(error);
            setErrorMessage(error.message);
          } finally {
            toggleLoading(false);
          }
          return;
        }
        // onChange && onChange(option, meta);
        // setInputValue(option ? option.value : "");
      },
      [onChange],
    );
    const handleFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        toggleShowValue(false);
        if (value) {
          setInputValue((value as OptionType).value);
        }
        onFocus && onFocus(event);
      },
      [value, onFocus],
    );

    useEffect(() => {
      if (value === null) {
        setInputValue("");
        return;
      }
    }, [value]);
    useEffect(() => {
      if (isDisabled) return;

      toggleLoading(true);
      fetcher(inputValue).then((result) => {
        setOptions(result);
        toggleLoading(false);
      });
    }, [inputValue, isDisabled]);

    // useEffect(() => {
    //   if (suggestionsValue !== inputValue) {
    //     setInputValue(suggestionsValue);
    //   }
    // }, [toggle]);

    return (
      <>
        <Select
          inputValue={inputValue}
          onInputChange={handleInputChange}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleFocus}
          filterOption={truthy}
          options={options}
          controlShouldRenderValue={showValue && !!value}
          autocomplete
          optionComponent={optionComponent}
          isSearchable
          isLoading={loading}
          isDisabled={isDisabled}
          required={required}
          readOnly={readOnly}
          smallFontSize={smallFontSize}
          error={error}
          errorMessage={errorMessage || undefined}
          {...props}
        />
        {errorMessage && (
          <div className="mt-3 rounded-xl bg-error-light px-4 py-3">
            <div className="flex flex-row items-start justify-start gap-2">
              <div className="h-6 w-6">
                <ExclamationMarkIcon />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-textSmall">{errorMessage}</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  },
);

export default WarehouseAutocomplete;

const ExclamationMarkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.47153 4.42496C10.5813 2.4521 13.4202 2.4521 14.5285 4.42496L22.623 18.8153C23.711 20.7505 22.314 23.1382 20.096 23.1382H3.90542C1.68594 23.1382 0.288978 20.7505 1.37696 18.8153L9.47153 4.42496ZM13.4506 18.7877C13.4506 19.1725 13.2978 19.5415 13.0257 19.8135C12.7537 20.0856 12.3847 20.2384 12 20.2384C11.6153 20.2384 11.2463 20.0856 10.9742 19.8135C10.7022 19.5415 10.5494 19.1725 10.5494 18.7877C10.5494 18.403 10.7022 18.034 10.9742 17.762C11.2463 17.4899 11.6153 17.3371 12 17.3371C12.3847 17.3371 12.7537 17.4899 13.0257 17.762C13.2978 18.034 13.4506 18.403 13.4506 18.7877ZM12 7.18263C11.6153 7.18263 11.2463 7.33547 10.9742 7.60751C10.7022 7.87956 10.5494 8.24854 10.5494 8.63327V12.9852C10.5494 13.3699 10.7022 13.7389 10.9742 14.0109C11.2463 14.283 11.6153 14.4358 12 14.4358C12.3847 14.4358 12.7537 14.283 13.0257 14.0109C13.2978 13.7389 13.4506 13.3699 13.4506 12.9852V8.63327C13.4506 8.24854 13.2978 7.87956 13.0257 7.60751C12.7537 7.33547 12.3847 7.18263 12 7.18263Z"
        fill="#EE5042"
      />
    </svg>
  );
};
